/* Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;800&display=swap');

/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
}

/* Title Container */
.about-me .title-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.about-me .background-title,
.about-me .foreground-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
}

.about-me .background-title {
  font-size: 175px;
  color: #fff;
  -webkit-text-stroke: 1px #E0E0E0;
}

.about-me .foreground-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: #000;
  padding-top: 1%;
}

/* Contact Wrapper */
.contact-wrapper1 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  max-width: 70%;
  margin: 0 auto;
  padding-right: 20px;
}

.img-profile {
  width: 400px;
  height: auto;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Text Styling */
.contact-wrapper1 h2,
.contact-wrapper1 p,
.contact-wrapper1 .personal-info p {
  font-family: 'Roboto', sans-serif;
  color: #000;
  font-size: 17px;
  padding-bottom: 15px;
}

.personal-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.column p {
  font-weight: 1000;
  font-size: 14px;
}

.column p strong {
  font-weight: 700;
  display: inline-block;
}

.contact-wrapper1 h2 {
  font-size: 30px;
}

.contact-wrapper1 .personal-info p {
  font-weight: 350;
  font-size: 16px;
}

/* Button Styling */
.button-container {
  display: flex;
  gap: 20px;
}

.about-me-btn {
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
  padding: 15px 25px; /* Increased padding */
  text-align: center;
  text-decoration: none;
  display: flex; /* Use flexbox */
  justify-content: center; /* Center text horizontally */
  align-items: center; /* Center text vertically */
  font-size: 15px; /* Increased font size */
  transition: background-color 0.4s ease, color 0.4s ease;
  cursor: pointer;
  border-radius: 30px;
  white-space: nowrap; /* Prevent buttons from having extra space */
}

.about-me-btn:hover {
  background-color: #000;
  color: #fff;
  border-color: #000;
}

.hire-me, .learn-more {
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
}
/* Media Query per dispositivi mobili */
@media (max-width: 1284px) {
  .about-me {
    padding: 20px 20px 20px 0px;
  }

  .about-me .title-container {
    align-items: center; /* Centro il contenuto */
    text-align: center; /* Testo centrato */
    margin-bottom: 20px;
  }

  .about-me .background-title {
    font-size: 80px; /* Ridotto per dispositivi mobili */
    -webkit-text-stroke: 0.5px #E0E0E0;
    padding-left: 20px;
  }

  .about-me .foreground-title {
    font-size: 30px;
    margin-bottom: 10px; /* Spazio sotto il titolo */
    padding-left: 20px;
  }

  .contact-wrapper1 {
    display: flex;
    flex-direction: column; /* Disposizione verticale */
    align-items: center;
    gap: 20px; /* Spazio tra immagine e testo */
    max-width: 100%; /* Usa tutta la larghezza disponibile */
  }

  .img-profile {
    width: 265px; /* Ridotta per schermi piccoli */
    height: auto;
    margin: 0 auto 10px; /* Centro l'immagine e aggiungo spazio sotto */
    transform: translateX(20px); /* Sposto l'immagine di 20px a destra */
  }

  .text-container {
    align-items: flex-start;
    text-align: left;
    padding-right: 20px;
    width: 100%; /* Usa tutta la larghezza */
  }

  .text-container h2 {
    font-size: 1.3rem; /* Migliorato il font size */
    text-align: justify; /* Giustificato */
    margin-bottom: 10px; /* Spazio sotto il titolo */
  }

  .text-container p {
    font-size: 13px; /* Font size ridotto per leggibilità */
    line-height: 1.4; /* Migliorata la spaziatura tra righe */
    text-align: justify;
    margin-bottom: 10px; /* Spazio tra paragrafi */
  }

  .personal-info {
    display: grid;
    grid-template-columns: 1fr; /* Una colonna */
    width: 100%; /* Usa tutta la larghezza */
    text-align: left; /* Testo allineato a sinistra */
  }

  .column p {
    font-size: 12px; /* Ridotto per dispositivi mobili */
    line-height: 1.1; /* Migliorata leggibilità */
  }

  .column p strong {
    display: inline-block;
    font-weight: bold;
  }

  .button-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Bottoni su due colonne affiancate */
    width: 100%; /* Allineati alla larghezza del contenuto */
    margin-top: 0px; /* Spazio sopra i bottoni */
  }

  .about-me-btn {
    font-size: 13px; /* Font size più piccolo */
    padding: 8px 0px; /* Spaziatura ridotta */
    border-radius: 15px; /* Bottoni più arrotondati */
  }
}
