video {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    
}

.works-section {
    
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.title-line-w {
    position: absolute;
    top: calc(50% + 1.5rem);
    width: 100px;
    height: 2px;
    background-color: #fff;
}

.title-work {
    position: absolute;
    color: #fff;
    padding-bottom: 20px;
}

