@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;800&display=swap'); /* Importa il font 'Roboto' da Google Fonts con vari pesi (400, 700, 800) */

body {
  font-family: 'Roboto', sans-serif; /* Imposta il font 'Roboto' per l'intero documento */
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
}


.cards {
  padding: 0px 64px 64px 64px; /* top | right | bottom | left */
  background: #fff; /* Imposta il colore di sfondo a bianco */
}

.title-container {
  position: relative; /* Imposta il contenitore in una posizione relativa per posizionamento degli elementi assoluti */
  display: flex; /* Usa il layout flexbox */
  flex-direction: column; /* Dispone gli elementi in una colonna */
  align-items: center; /* Centra gli elementi orizzontalmente */
  justify-content: center; /* Centra gli elementi verticalmente */
  height: 100%; /* Imposta l'altezza al 100% */
  margin-bottom: 2rem; /* Aggiunge 2 rem di margine sotto */
}

.background-title, .foreground-title {
  font-family: 'Roboto', sans-serif; /* Usa il font 'Roboto' */
  font-weight: 800; /* Imposta il peso del font a 800 (grassetto) */
}

.background-title {
  font-size: 175px; /* Imposta la dimensione del font a 175px */
  color: #fff; /* Imposta il colore del testo a bianco */
  -webkit-text-stroke: 1px #E0E0E0; /* Aggiunge un contorno al testo di 1px di colore #E0E0E0 */
}

.foreground-title {
  position: absolute; /* Posiziona il testo in modo assoluto */
  top: 50%; /* Posiziona il testo al 50% dall'alto */
  left: 50%; /* Posiziona il testo al 50% da sinistra */
  transform: translate(-50%, -50%); /* Centra il testo con una trasformazione */
  font-size: 50px; /* Imposta la dimensione del font a 50px */
  color: #000; /* Imposta il colore del testo a nero */
  padding-top: 1%; /* Aggiunge 1% di padding in cima */
}

.cards__container {
  width: 100%;
  margin-left: 7%; /* Aggiunge un margine sinistro di 400px */
  overflow: visible; /* Imposta il comportamento di overflow su visibile */
  position: relative; /* Posiziona il contenitore in modo relativo */
  padding-bottom: 60px; /* Aggiunge 60px di padding in basso per fare spazio alle frecce */
}

.mySwiper {
  position: static; /* Imposta la posizione a statica */
}

.cards__item {
  display: flex; /* Usa il layout flexbox */
  border-radius: 20px; /* Arrotonda i bordi di 20px */
  height: 400px; /* Imposta l'altezza a 450px */
  width: 270px; /* Imposta la larghezza a 320px */
}

.cards__item__pic-wrap {
  position: relative; /* Posiziona il contenitore in modo relativo */
  width: 100%; /* Imposta la larghezza al 100% */
  height: 100%; /* Imposta l'altezza al 100% */
  overflow: hidden; /* Nasconde l'overflow */
  border-radius: 20px; /* Arrotonda i bordi di 20px */
}

.cards__item__pic-wrap::after {
  content: attr(data-category); /* Usa l'attributo data-category come contenuto */
  position: absolute; /* Posiziona l'elemento in modo assoluto */
  bottom: 10px; /* Posiziona l'elemento 10px dal basso */
  left: 10px; /* Posiziona l'elemento 10px da sinistra */
  padding: 6px 8px; /* Aggiunge 6px di padding verticale e 8px di padding orizzontale */
  max-width: calc(100% - 20px); /* Imposta la larghezza massima come il 100% meno 20px */
  font-size: 15px; /* Imposta la dimensione del font a 15px */
  font-weight: 700; /* Imposta il peso del font a 700 */
  color: #fff; /* Imposta il colore del testo a bianco */
  border-radius: 5px; /* Arrotonda i bordi di 5px */
  box-sizing: border-box; /* Include il padding e il bordo nella larghezza e altezza totali */
}

.cards__item__img {
  display: block; /* Imposta il display a block */
  width: 100%; /* Imposta la larghezza al 100% */
  height: 100%; /* Imposta l'altezza al 100% */
  object-fit: cover; /* Ridimensiona l'immagine per coprire il contenitore mantenendo il rapporto */
  transition: transform 0.3s ease; /* Aggiunge una transizione sulla trasformazione con durata di 0.3s */
}

.cards__item__img:hover {
  transform: scale(1.05); /* Scala l'immagine del 105% al passaggio del mouse */
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute; /* Posiziona le frecce in modo assoluto */
  bottom: 10px; /* Posiziona le frecce 10px dal basso */
  top: auto; /* Imposta il valore top su auto */
  width: 17px; /* Imposta la larghezza a 17px */
  height: 17px; /* Imposta l'altezza a 17px */
  background-color: transparent; /* Imposta lo sfondo a trasparente */
  color: #333; /* Imposta il colore a #333 (grigio scuro) */
  cursor: pointer; /* Cambia il cursore a puntatore */
}



.swiper-button-next {
  left: 40px; /* Posiziona la freccia destra 50px da sinistra */
}

.swiper-button-prev:after,
.swiper-button-next:after {
  content: ''; /* Non aggiunge contenuto */
  position: absolute; /* Posiziona l'elemento in modo assoluto */
  width: 10px; /* Imposta la larghezza a 10px */
  height: 10px; /* Imposta l'altezza a 10px */
  border-top: 2px solid #000; /* Aggiunge un bordo superiore nero di 2px */
  border-right: 2px solid #000; /* Aggiunge un bordo destro nero di 2px */
}

.swiper-button-prev:after {
  transform: rotate(-135deg); /* Ruota l'elemento di -135 gradi */
  left: 6px; /* Posiziona l'elemento 6px da sinistra */
}

.swiper-button-next:after {
  transform: rotate(45deg); /* Ruota l'elemento di 45 gradi */
  right: 6px; /* Posiziona l'elemento 6px da destra */
}

.swiper-button-prev:hover:after,
.swiper-button-next:hover:after {
  border-color: #555; /* Cambia il colore del bordo a #555 (grigio medio) al passaggio del mouse */
}
@media screen and (max-width: 768px) {
  .cards .title-container {
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .cards .background-title {
    font-size: 80px;
    -webkit-text-stroke: 0.5px #E0E0E0;
    
    padding-top: 10px;
    text-align: center; /* Centrato */
  }

  .cards .foreground-title {
    font-size: 30px;
    margin-bottom: 10px;
    color: #000;
    padding-top: 12px;
  }

  .cards {
    padding: 20px 20px 40px 20px;
  }

  .title-container {
    margin-bottom: 1.5rem;
  }

  .background-title {
    font-size: 120px;
    -webkit-text-stroke: 1px #ccc;
  }

  .foreground-title {
    font-size: 36px;
    color: #000;
    padding-top: 10px;
  }

  .cards__container {
    margin-left: 0;
    padding-bottom: 40px;
  }

  .mySwiper {
    width: 100%;
  }

  .cards__item {
    height: 300px;
    width: 220px;
    margin: 0 auto;
  }

  .cards__item__pic-wrap {
    border-radius: 15px;
  }

  .cards__item__pic-wrap::after {
    font-size: 12px;
    padding: 4px 6px;
    bottom: 8px;
    left: 8px;
  }

  .cards__item__img {
    transition: transform 0.3s ease;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    bottom: -10px;
    z-index: 10;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
  }

  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    left: 40px;
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    color: #000;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    content: '';
    width: 10px;
    height: 10px;
    border-top: 1.5px solid #333;
    border-right: 1.5px solid #333;
  }

  .swiper-button-prev::after {
    transform: rotate(-135deg);
  }

  .swiper-button-next::after {
    transform: rotate(45deg);
  }
}
