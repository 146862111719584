#contact {
  margin: 0;
  padding: 0;
  background-color: #111111; /* Background only for contact section */
  padding-bottom: 100px;
  /* If #contact is not the full height of the page, you may want to set a minimum height */
  min-height: 100vh; /* This ensures that the contact section takes up the minimum height of the full viewport */
  justify-content: space-between; 
}

#contact {
  width: 100%;
  height: 100%;
}

/* start*/

video {
  width: 100%;
  height: 45vh;
  object-fit: cover;
  
}

.c-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 110%;
}

.title-line-c {
  position: absolute;
  top: calc(50% + 1.5rem);
  width: 150px;
  height: 2px;
  background-color: #fff;
}

.title-c {
  position: absolute;
  color: #fff;
  padding-bottom: 20px;
}
/*end start*/


.section-header {
  text-align: center;
  margin:  auto;
  padding: 40px 0;
  font: 300 60px 'Oswald', sans-serif;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 6px;
}

.contact-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 50px;
  position: relative;
  max-width: 840px;
  padding-right: 20px;
}
/* Begin Left Contact Page */

.img_profile{
  width: 310px;
  height: 310px;
}

/* ------------------------ */

/* Begin Right Contact Page */
.direct-contact-container {
  max-width: 400px;
}

/* Location, Phone, Email Section */
.contact-list {
  list-style-type: none;
  margin-left: -30px;
  padding-right: 20px;
}

.list-item {
  line-height: 4;
  color: #aaa;
}

.contact-text {
  font: 300 18px 'Lato', sans-serif;
  letter-spacing: 1.9px;
  color: #bbb;
}

.place {
  margin-left: 60px;
}

.phone {
  margin-left: 60px;
}

.gmail {
  margin-left: 60px;
}

.contact-text a {
  color: #bbb;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  color: #fff;
  text-decoration: none;
}

/* Social Media Icons */
.social-media-list {
  position: relative;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  z-index: 100;
}

.social-media-list li a {
  color: #fff;
}

.social-media-list li {
  position: relative; 
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 3px;
  line-height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(40, 40, 40);
  cursor: pointer; 
  transition: all .2s ease-in-out;
}

.social-media-list li:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #fff;
  transition: all .2s ease-in-out;
  pointer-events: none;
}

.social-media-list li:hover {
  background-color: #fff; 
}

.social-media-list li:hover:after {
  opacity: 1;  
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37,0.74,0.15,1.65);
}

.social-media-list li:hover a {
  color: #000;
}

.copyright {
  font: 200 14px 'Oswald', sans-serif;
  color: #555;
  letter-spacing: 1px;
  text-align: center;
}

hr {
  border-color: rgba(255,255,255,.6);
}
/* ... previous CSS before media queries ... */

@media screen and (max-width: 850px) {
  .contact-wrapper {
    flex-direction: column;
  }

  .direct-contact-container,
  .form-horizontal {
    margin: 0 auto;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 300px;
  }
  
  .social-media-list li {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
  
  .social-media-list li:after {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  
  .img_profile{
    width: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 569px) {

  .direct-contact-container,
  .form-horizontal {
    float: none;
    margin: 0 auto;
  }
  
  .form-control, textarea {
    margin: 0 auto;
  }
  
  .name, .email, textarea {
    width: 280px;
  }
  
  .direct-contact-container {
    margin-top: 60px;
    max-width: 280px;
  }
  
  .social-media-list {
    left: 0;
  }
  
  .social-media-list li {
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 2rem;
  }
  
  .social-media-list li:after {
    width: 55px;
    height: 55px;
    line-height: 55px;
  }
  
}


