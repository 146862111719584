body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}
.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: black;
  color: white;
  font-family: Arial, sans-serif;
}

.text-container {
  padding-top: 0px;
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.profile-image {
  width: 300px;
  height: 300px;
  border-radius: 1000px;
  object-fit: cover;
  margin-left: 20px;

  transition: transform 0.3s ease-in-out;
}

.intro {
  font-size: 1.5em;
  margin-bottom: 0;
  animation: fadeIn 2s ease-in-out;
}

@font-face {
  font-family: 'DESIGNER';
  src: url('../../assets/DESIGNER.otf');
}

.name {
  font-family: 'DESIGNER';
  color: #fff;
  text-transform: uppercase;
  font-size: 4em;
  text-shadow: 2px 2px 4px #000;
}

.role {
  font-size: 1.5em;
  margin-bottom: 20px;
  animation: fadeIn 2s ease-in-out 0.5s;
}

/*BUTTON*/

.btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-left: 70px;
  padding-top: 20px;
}

.button {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition: background-color 0.4s ease;
  cursor: pointer;
  border-radius: 12px;
}

.button:hover {
  background-color: #fff;
  color: #000;
  border-color: #fff;
}

.play-btn {
  width: 60px;
  height: 60px;
  background: radial-gradient( rgba(0, 0, 0, 0.8) 60%, rgba(255, 255, 255, 1) 70%);
  border-radius: 50%;
  position: relative;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.play-btn:hover {
  width: 60px;
  height: 60px;
  background: radial-gradient( rgba(0, 0, 0, 0.8) 60%, rgba(255, 255, 255, 1) 70%);
  border-radius: 50%;
  position: relative;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

/* triangle */
.play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  transform-origin: center center;
  width: 0;
  height: 0;
  border-top: 13.5px solid transparent;
  border-bottom: 13.5px solid transparent;
  border-left: 22.5px solid #fff;
  z-index: 100;
  -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  outline: none;
}

/* pulse wave */
.play-btn:before {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, .75);
  top: -33%;
  left: -33%;
  background: rgba(198, 16, 0, 0);
  outline: none;
}

@-webkit-keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

.text1 {
  margin-left: -35px;
  padding-right: 60px;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

/*----------------------------------------------------------------------------------------*/

/* Animazione fadeIn */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*----------------------------------------------------------------------------------------*/
/* Media query per schermi piccoli */
@media (max-width: 1284px) {
  .profile {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra il contenuto */
    justify-content: space-between; /* Distribuisce lo spazio simmetricamente */
    height: 100vh;
    padding: 0px 0px 120px 0px;
  }

  .profile-image {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 150px; /* Distanza tra il top e l'immagine */
  }

  .text-container {
    display: flex;
    flex-direction: column;
    text-align: left; /* Allinea il testo a sinistra */
    width: 100%; /* Riduce la larghezza per un layout più ordinato */
  }

  .intro {
    font-size: 1.2em;
    margin: 0;
  }

  .name {
    font-size: 2.5em;
    margin: 0;
  }

  .role {
    font-size: 1.3em;
    margin: 5px 0;
  }

  /* Bottoni posizionati in basso */
  .btn-container {
    display: flex;
    flex-direction: row; /* Bottoni affiancati */
    justify-content: center; /* Centrati orizzontalmente */
    align-items: center;
    gap: 20px; /* Distanza tra i bottoni */
    margin-top: 0px; /* Distanza tra il testo e i bottoni */
    margin-bottom: 100px; /* Spazio tra i bottoni e il fondo */
  }

  .button {
    font-size: 14px;
    padding: 10px 20px;
    background-color: #fff;
    color: black;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .button:hover {
    background-color: #000;
    color: #fff;
  }

  .play-btn {
    width: 50px;
    height: 50px;
    background: radial-gradient( rgba(0, 0, 0, 0.8) 60%, rgba(255, 255, 255, 1) 70%);
    border-radius: 50%;
    position: relative;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  .play-btn:hover {
    width: 50px;
    height: 50px;
    background: radial-gradient( rgba(0, 0, 0, 0.8) 60%, rgba(255, 255, 255, 1) 70%);
    border-radius: 50%;
    position: relative;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  .text1 {
    font-size: 12px;
    padding-left: 25px;
    margin-top: 5px; /* Piccolo margine sopra la scritta */
    text-align: center; /* Centra la scritta */
    color: #fff; /* Colore del testo */
  }
}
